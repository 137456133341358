class DataStore {
    constructor() {
        /* scrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.docScroll = '';
        this.win = $(window);
        this.body = $('body');

        /* window ++++++++++++++++++++++++++++++++++++++++++*/

        this.docWidth = $(document).width();
        this.docHeight = $(document).height();
        this.winHeight = $(window).height();

        /* non editable ++++++++++++++++++++++++++++++++++++++++++*/
        this.endOfResize = false;

        /* browser ++++++++++++++++++++++++++++++++++++++++++*/

        this.isIe = false;
        this.isFirefox = false;
        this.isSafari = false;
        this.isNoIe = false;
        this.touch = false;
        this.touchBreakPoint = 768;

        /* ids ++++++++++++++++++++++++++++++++++++++++++*/
        this.globalIds = JSON.parse(window.wp_urls.globalIds);
        this.wp_urls = window.wp_urls.ajaxObj;

        /* fancybox ++++++++++++++++++++++++++++++++++++++++++*/
        this.fancyBoxOnceOpened = false;

        /* smoothscrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.scrollEventListener = 'wheel';
        this.smoothDisabled = false;

        /* masonry ++++++++++++++++++++++++++++++++++++++++++*/
        this.grid = '';

        /* lazyload ++++++++++++++++++++++++++++++++++++++++++*/
        this.lazyLoadInstance = '';

        /* Map ++++++++++++++++++++++++++++++++++++++++++*/
        this.mapMarkers = [];
        this.map = '';

        /* events ++++++++++++++++++++++++++++++++++++++++++*/
        this.ajaxActive = false;
        this.calendar = '';
        this.calendarContainer = '';
        this.addedEvents = [];

        /* popup ++++++++++++++++++++++++++++++++++++++++++*/
        this.popup = $('#popup');

        /* header  scroll ++++++++++++++++++++++++++++++++++++++++++*/
        this.header = $('.headerInner #headBar');
        this.navPos = $('.headerInner').height();

        /* bigheader  scroll ++++++++++++++++++++++++++++++++++++++++++*/
        this.headerScroll = false;

        this.headerScrollWrap = $('#headerSlider, #bigHeaderWrapper.hasImg');
        this.headerScrollImg = $('#headerSlider .swiper-wrapper, #bigHeaderWrapper .headerBg');

        this.headerTextWrap = $('.headerTextWrap');
        this.headerTextScroll = $('.headerItems, .bigHeaderContent .inner').length;

        this.headerScrollEnd = '';
        this.headerScrollAmount = '';

        this.blurDuplicator = 1;

        /* aboutTsr ++++++++++++++++++++++++++++++++++++++++++*/

        this.aboutTsrScroll = false;

        this.aboutTsrScrollWrap = $('.aboutTsr');
        this.aboutTsrScrollTop = '';

        this.aboutTsrScrollItemsObject = [];

        // min-max- Werte für scroll pro pixel
        this.minTranslate = 2;
        this.maxTranslate = 7;
    }
}

export default (new DataStore());
