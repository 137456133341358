import '@fancyapps/fancybox';
import './plugins/inView';
import './plugins/simple-calendar';
import Swiper, {
    Navigation, Pagination, Controller, Lazy, Autoplay
} from 'swiper';

import mapBox from './inc/hmMaps';
// import smoothScroll from './inc/smoothScroll';
import dataStore from './inc/dataStore';
// import { herrlichesMenu, mobileSubmenu, hmNavInit } from './inc/header';
// import browserDetection from './inc/browserDetection';

import observeIntersection from './inc/observeIntersection';

Swiper.use(
    [Navigation, Pagination, Controller, Lazy, Autoplay]
);

const LazyLoad = require('vanilla-lazyload');

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
$(document).ready(() => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
    // browserDetection();

    /* Herrlich Media Nav Init ++++++++++++++++++++++++++++++++++++++++++*/
    // hmNavInit();

    // Smooth Mousewheel Scrolling for Chrome & Safari
    // smoothScroll();

    const toggleMobileNav = () => {
        const mainnavCon = document.getElementById('mainnavCon');
        mainnavCon.classList.toggle('open');
    };

    const navToggle = document.getElementById('navToggle');
    navToggle.addEventListener('click', () => {
        navToggle.classList.toggle('open');
        toggleMobileNav();
    });

    const subMenus = document.querySelectorAll('li.menu-item-has-children');
    subMenus.forEach((element) => {
        element.addEventListener('mouseenter', (e) => {
            const subMenu = e.target.querySelector('.sub-menu');
            subMenu.classList.add('vis');
        });
        element.addEventListener('mouseleave', (e) => {
            const subMenu = e.target.querySelector('.sub-menu');
            subMenu.classList.remove('vis');
        });
    });

    const archiveAccordion = () => {
        const container = document.getElementById('archives');
        if (container) {
            const lists = container.querySelectorAll('.archives_list');
            Array.from(lists).slice(1).forEach((list) => {
                list.classList.add('hidden');
            });
            const triggers = container.querySelectorAll('h3.archive_year');
            triggers.forEach((trigger) => {
                trigger.addEventListener('click', (e) => {
                    lists.forEach((list) => {
                        list.classList.add('hidden');
                    });
                    e.target.nextSibling.nextSibling.classList.remove('hidden');
                });
            });
        }
    };

    archiveAccordion();

    if (dataStore.popup.length) {
        const id = dataStore.popup.data('id');
        const storageName = `popup_${id}`;
        showPopup(storageName);
    }

    $('.closePopup').on('click', () => {
        dataStore.popup.removeClass('vis');
    });

    function showPopup() {
        setTimeout(() => {
            dataStore.popup.addClass('vis');
        }, 1500);
    }

    /* Search ++++++++++++++++++++++++++++++++++++++++++*/

    $('#searchBtn').on('click', () => {
        $('#searchBar').toggleClass('vis');
    });

    /* header Scroll ++++++++++++++++++++++++++++++++++++++++++*/

    if (dataStore.headerScrollImg.length) {
        dataStore.headerScroll = true;
        dataStore.headerScrollEnd = dataStore.headerScrollWrap.height() + dataStore.headerScrollWrap.offset().top;
        dataStore.headerScrollAmount = 1 / dataStore.headerScrollEnd;
    }

    /* about Scroll ++++++++++++++++++++++++++++++++++++++++++*/

    if (dataStore.aboutTsrScrollWrap.length) {
        dataStore.aboutTsrScroll = true;
        dataStore.aboutTsrScrollTop = dataStore.aboutTsrScrollWrap.offset().top;

        if (window.innerWidth >= 992) {
            $('.aboutTsr .scrollItem').each((i, el) => {
                let randomTranslate = Math.floor(
                    Math.random()
                            * (dataStore.maxTranslate
                                - dataStore.minTranslate
                                + 1)
                ) + dataStore.minTranslate;

                if (i === 0) {
                    randomTranslate *= -1;
                }

                const tempObject = {
                    element: $(el),
                    translate: randomTranslate
                };
                dataStore.aboutTsrScrollItemsObject.push(tempObject);
            });
        }
    }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    lazyloadUpdate();

    // FANCYBOX
    $('[data-fancybox]').fancybox({
        afterLoad() {
            dataStore.smoothDisabled = true;
        },
        beforeClose() {
            dataStore.smoothDisabled = false;
        }
    });

    /**** Map Box ***/
    const map = document.getElementById('map');
    if (map) {
        observeIntersection('map', mapBox, true);
    }

    const initGif = () => {
        const gifTag = document.getElementById('gifTag__js');
        gifTag.setAttribute('src', gifTag.dataset.src);
    };

    const gifTeaser = document.getElementById('animationTsr');
    if (gifTeaser) {
        observeIntersection('animationTsr', initGif, true);
    }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $(window).scroll(() => {
        dataStore.docScroll = $(window).scrollTop();
        dataStore.header.toggleClass('scrolled', dataStore.docScroll >= dataStore.navPos);

        if (dataStore.headerScroll) {
            const scrollOpacity = dataStore.headerScrollAmount * (dataStore.docScroll * dataStore.blurDuplicator);

            dataStore.headerScrollImg.css({
                opacity: 1 - scrollOpacity
            });

            if (dataStore.headerTextScroll) {
                dataStore.headerTextWrap.css({
                    opacity: 1 - scrollOpacity,
                    transform: `translate(0,${dataStore.docScroll / 10}px)`,
                    '-webkit-transform': `translate(0,${dataStore.docScroll / 10}px)`
                });
            }
        }

        if (dataStore.aboutTsrScroll) {
            const ContentDiff = dataStore.docScroll + dataStore.winHeight - dataStore.aboutTsrScrollTop;

            if (ContentDiff >= 0) {
                const differntPart = ContentDiff / 100;

                for (let a = 0; a < dataStore.aboutTsrScrollItemsObject.length; a++) {
                    const specialTrans = differntPart * dataStore.aboutTsrScrollItemsObject[a].translate;

                    dataStore.aboutTsrScrollItemsObject[a].element.css({
                        transform: `translate(0,${specialTrans}px)`,
                        '-webkit-transform': `translate(0,${specialTrans}px)`
                    });
                }
            }
        }
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    if (dataStore.headerScroll) {
        dataStore.headerScrollEnd = dataStore.headerScrollWrap.height() + dataStore.headerScrollWrap.offset().top;
        dataStore.headerScrollAmount = 1 / dataStore.headerScrollEnd;
    }

    if (dataStore.aboutTsrScroll) {
        dataStore.aboutTsrScrollTop = dataStore.aboutTsrScrollWrap.offset().top;
    }

    $(window).trigger('scroll');

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();
    }, 250);
});

function afterResize() {
    // mobileSubmenu();
    // herrlichesMenu.destroy();
    // herrlichesMenu.init();
}

$(window).on('load', () => {
    /* Veranstaltungen  ++++++++++++++++++++++++++++++++++++++++++*/

    const calendarInit = () => {
        const firstPath = window.location.pathname.split('/')[1];

        let readMore = 'mehr erfahren';
        let withInstructor = 'Mit';
        let from = 'von';
        let until = 'bis';
        let clock = 'Uhr';
        let starting = 'ab';

        if (firstPath === 'en') {
            readMore = 'read more';
            withInstructor = 'With';
            from = 'from';
            until = 'until';
            clock = '';
            starting = 'starting';
        }

        dataStore.calendarContainer = $('#calender').simpleCalendar({
            months: [
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember'
            ],
            days: ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'],
            disableEventDetails: true, // disable showing event details
            fixedStartDay: false,

            onInit: (calendar) => {
                getAjaxPosts(
                    calendar.currentDate.getMonth(),
                    calendar.currentDate.getFullYear()
                );
            },

            onMonthChange: (month, year) => {
                dataStore.calendar = dataStore.calendarContainer.data(
                    'plugin_simpleCalendar'
                );
                getAjaxPosts(month, year);
            },

            onDateSelect: (date, events) => {
                $('#eventPreview ul li').removeClass('vis');
                setTimeout(() => {
                    $('#eventPreview ul').empty();
                    let title = '';
                    let day = '';
                    let summary = '';
                    let url = '';
                    let supervisor = '';

                    for (let i = events.length - 1; i >= 0; i--) {
                        title = `<h3>${events[i].summary}</h3>`;

                        if (events[i].endDay === '') {
                            // eslint-disable-next-line max-len
                            day = `<span class="date">${events[i].startDay} ${starting} ${events[i].startTime} ${clock}</span>`;
                        } else if (
                            events[i].startDay === events[i].endDay
                        ) {
                            day = `<span class="date">${events[i].startDay} ${from}
                        ${events[i].startTime} ${until} ${events[i].endTime} ${clock}</span>`;
                        } else {
                            day = `<span class="date">${events[i].startDay} ${from}
                        ${events[i].startTime} Uhr ${until} ${events[i].endDay} ${events[i].endTime} ${clock}</span>`;
                        }

                        if (events[i].description !== '') {
                            summary = `<p>${events[i].description}</p>`;
                        }

                        if (events[i].supervisor !== '') {
                            supervisor = `<span class="supervisor">${withInstructor} ${events[i].supervisor}</span>`;
                        }

                        if (events[i].url !== '') {
                            url = `<span class="arrowBtn white">${readMore}</span>`;
                        }

                        $('#eventPreview ul')
                            .append(`<li><a class="arrowBtnWrap" href="
                        ${events[i].url}">${title
                                + day
                                + summary
                                + supervisor
                                + url}</a></li>`);
                    }

                    for (
                        let i = $('#eventPreview ul li').length - 1;
                        i >= 0;
                        i--
                    ) {
                        setTimeout(() => {
                            $('#eventPreview ul li')
                                .eq(i)
                                .addClass('vis');
                        }, 150 * i);
                    }
                }, 300);
            }
        });

        dataStore.calendar = dataStore.calendarContainer.data(
            'plugin_simpleCalendar'
        );
    };

    const cal = document.getElementById('eventsTsr');
    if (cal) {
        observeIntersection('eventsTsr', calendarInit, true);
    }

    /* Swiper  ++++++++++++++++++++++++++++++++++++++++++*/
    const headerSlider = new Swiper('#headerSliderInner', {
        // Optional parameters
        loop: true,
        autoplay: true,
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 2
        },
        pagination: {
            el: '#headerSlider .swiper-pagination',
            clickable: true,
            renderBullet: (index, className) => {
                let val;

                if (index + 1 < 10) {
                    val = `<span class="${className}">0${index + 1}</span>`;
                }

                if (index + 1 >= 10) {
                    val = `<span class="${className}">${index + 1}</span>`;
                }

                return val;
            }
        }
    });

    headerSlider.on('slideChange', () => {
        const newCurrent = $('.headerItems li').eq(headerSlider.realIndex);

        $('.headerItems li.current').removeClass('current');
        newCurrent.addClass('current');
    });

    /* Aktuelles Slider  ++++++++++++++++++++++++++++++++++++++++++*/

    if ($('#newsMainSlider').length) {
        const swiper1 = new Swiper('#newsMainSlider', {
            slidesPerView: 'auto',
            loop: true,
            centeredSlides: false,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 2,
            },
            navigation: {
                nextEl: '#newsTeaser .swiper-button-next',
                prevEl: '#newsTeaser .swiper-button-prev',
            },
        });

        const swiper2 = new Swiper('#newsSideSlider', {
            slidesPerView: 'auto',
            loop: true,
            allowTouchMove: false,
            centeredSlides: false
        });

        swiper1.controller.control = swiper2;
        // swiper2.controller.control = swiper1;

        swiper1.on('slideChange', () => {
            const newCurrent = $('#newsContent li').eq(swiper1.realIndex);

            $('#newsContent li.current').removeClass('current');
            newCurrent.addClass('current');

            $('#newsContent').css({
                'min-height': `${newCurrent.height()}px`
            });
        });
    }

    const contentSliders = document.querySelectorAll(
        '.sliderBlock'
    );

    if (contentSliders.length) {
        contentSliders.forEach((slider) => {
            const swiperSlider = slider.querySelector('.swiper-container');
            const pagination = slider.querySelector('.swiper-pagination');
            const next = slider.querySelector('.swiper-button-next');
            const prev = slider.querySelector('.swiper-button-prev');
            new Swiper(swiperSlider, {
                slidesPerView: 'auto',
                loop: true,
                centeredSlides: false,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 2
                },
                navigation: {
                    nextEl: next,
                    prevEl: prev
                },
                pagination: {
                    el: pagination,
                    clickable: true,
                    renderBullet: (index, className) => {
                        let val;

                        if (index + 1 < 10) {
                            val = `<span class="${className}">0${index
                                + 1}</span>`;
                        }

                        if (index + 1 >= 10) {
                            val = `<span class="${className}">${index
                                + 1}</span>`;
                        }

                        return val;
                    }
                }
            });
        });
    }

    /* left/right Buttons ++++++++++++++++++++++++++++++++++++++++++*/

    $('.swiper-pagination-bullet, .simple-calendar-btn,.day.has-event').each((i, el) => {
        let cube = '<svg class="cube" xmlns="http://www.w3.org/2000/svg"';
        cube += 'width="48.612" height="48.612" viewBox="0 0 48.612 48.612">';
        cube += '<g data-name="Gruppe 106" transform="translate(-166.247 -636.14)">';
        cube += '<path class="cubeBox" d="M0,0H43L40.64,43.9-1.179,41.164Z"';
        cube += 'transform="matrix(0.999, -0.035, 0.035, 0.999, 169.247, 640.743)" fill="#fff">';
        cube += '</path><g transform="translate(0 -1.003)"><g>';
        cube += '<g transform="matrix(0.999, -0.035, 0.035, 0.999, 166.247, 638.783)" fill="none"';
        cube += 'stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">';
        cube += '<rect class="cubeLine" x="1.5" y="1.5" width="44" height="44" rx="2.5"';
        cube += 'fill="none"></rect></g></g></g></g></svg>';
        $(el).addClass('cubeHover').append(cube);
    });

    /* In View  ++++++++++++++++++++++++++++++++++++++++++*/
    $('.fadeIn').one('inview', (e, visible) => {
        if (visible === true) {
            $(e.currentTarget).not('.vis').addClass('vis');
        }
    });

    $('.fadeInRow').one('inview', (e, visible) => {
        if (visible === true) {
            $(e.currentTarget).find('> div, > li, > p').not('.vis').each((i, el) => {
                setTimeout(() => {
                    $(el).addClass('vis');
                }, 150 * i);
            });
        }
    });

    $('.mapboxgl-marker').one('inview', (e, visible) => {
        if (visible === true) {
            $(e.currentTarget).trigger('click');
        }
    });
});

// Lazyload Update
const lazyloadUpdate = function lazyloadUpdate() {
    if (dataStore.lazyLoadInstance !== '') {
        dataStore.lazyLoadInstance.update();
    }
};

/* Webp Support Detection  ++++++++++++++++++++++++++++++++++++++++++*/
async function supportsWebp() {
    if (!window.self.createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then((r) => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
}

// Add Webp to Background Imgaes when supported
(async() => {
    if (await supportsWebp()) {
        dataStore.body.addClass('webp');
        const lazyBg = document.querySelectorAll('[data-bg]');
        lazyBg.forEach((element) => {
            let { bg } = element.dataset;
            if (bg.match(new RegExp('.*(.jpg|.jpeg|.png)')) && !bg.includes('.webp') && !bg.includes('cdninstagram')) {
                bg = `${bg}.webp`;
                element.setAttribute('data-bg', bg);
            }
        });
    }

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy'
    });
    lazyloadUpdate();
})();

function getAjaxPosts(monthVal, yearVal) {
    dataStore.ajaxActive = true;
    const loader = document.getElementById('eventLoader');
    const note = document.getElementById('eventNote__js');
    loader.style.display = 'block';
    note.style.display = 'none';

    if (jQuery.inArray(`${monthVal}_${yearVal}`, dataStore.addedEvents) === -1) {
        dataStore.addedEvents.push(`${monthVal}_${yearVal}`);

        $.ajax({
            cache: false,
            timeout: 8000,
            url: dataStore.wp_urls,
            type: 'POST',
            data: ({
                action: 'ajaxLoadEvents',
                month: monthVal,
                year: yearVal
            }),
            success: (data) => {
                const $ajaxResponse = $(data);

                const noEventNote = document.getElementById('eventNote__js');

                if ($ajaxResponse[0].events.length) {
                    for (
                        let i = $ajaxResponse[0]
                            .events
                            .length - 1;
                        i >= 0;
                        i--
                    ) {
                        dataStore.calendar.addEvent(
                            $ajaxResponse[0]
                                .events[i]
                        );
                    }
                    noEventNote.style.display = 'none';
                }
            },
            error(jqXHR, textStatus, errorThrown) {
                console.log(`The following error occured: ${textStatus}`, errorThrown);
            },
            complete: () => {
                dataStore.ajaxActive = false;
                loader.style.display = 'none';

                // $('.day.has-event:not(.cubeHover)').each((i, el) => {
                //     let cube = '<svg class="cube" xmlns="http://www.w3.org/2000/svg"';
                //     cube += 'width="48.612" height="48.612" viewBox="0 0 48.612 48.612">';
                //     cube += '<g data-name="Gruppe 106" transform="translate(-166.247 -636.14)">';
                //     cube += '<path class="cubeBox" d="M0,0H43L40.64,43.9-1.179,41.164Z"';
                //     cube += 'transform="matrix(0.999, -0.035, 0.035, 0.999, 169.247, 640.743)" fill="#fff">';
                //     cube += '</path><g transform="translate(0 -1.003)"><g>';
                //     cube += '<g transform="matrix(0.999, -0.035, 0.035, 0.999, 166.247, 638.783)" fill="none"';
                //     cube += 'stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">';
                //     cube += '<rect class="cubeLine" x="1.5" y="1.5" width="44" height="44" rx="2.5"';
                //     cube += 'fill="none"></rect></g></g></g></g></svg>';

                //     $(el).addClass('cubeHover').append(cube);
                // });

                setTimeout(() => {
                    $('.day.has-event').removeClass('activeEvent');
                    $('.day.has-event').eq(0).trigger('click').addClass('activeEvent');
                }, 300);

                // eslint-disable-next-line func-names
                $('.day.has-event').on('click', function() {
                    $('.day.has-event').removeClass('activeEvent');
                    $(this).addClass('activeEvent');
                });
            }
        });
    } else {
        setTimeout(() => {
            $('.day.has-event').removeClass('activeEvent');
            $('.day.has-event')
                .eq(0)
                .trigger('click')
                .addClass('activeEvent');
        }, 300);
    }
}
